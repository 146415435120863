import axios from 'axios';
import authHeader from './auth-header';

// const API_URL = 'http://192.168.1.3:8000/api/';
// const API_URL = 'https://admin.ethermining.net/api/'
const API_URL = 'https://admin.ethblockchain.website/api/'

// const API_URL = 'http://localhost:8000/api/';

class UserService {

  getHelpers() {
    return axios.get(API_URL + 'setting/helpers');
  }

  applyWithdraw(data) {
    return axios.post(API_URL + 'withdraw/apply',data, { headers: authHeader() })
  }

  getEthToUsdtRate() {
    return axios.get(API_URL + "setting/eth_to_usdt_rate");
  }

  getTrxToUsdtRate() {
    return axios.get(API_URL + "setting/trx_to_usdt_rate");
  }

  getUserBalance() {
    return axios.get(API_URL + "user/balance-reports", {headers:authHeader()});
  }

  getMinerIncomes() {
    return axios.get(API_URL + "setting/miner_income");
  }

  exchangeEthToUsdt(data) {
    return axios.post(API_URL + "exchange/eth_to_usdt",data,{headers:authHeader()})
  }
}

export default new UserService();